import 'chartjs-adapter-date-fns';

const getChartOptions = (chartData, yAxisLabel, yAxisMin, yAxisMax, yAxisDecimals) => {
    let yTicksOptions = {
        color: '#ddd'
    };

    if (typeof yAxisDecimals !== 'undefined') {
        yTicksOptions.callback = function(value, index, values) {
            return Number(value).toFixed(yAxisDecimals);
        };
    }

    return {
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: 'yyyy-MM-dd HH:mm',
                    displayFormats: {
                        hour: 'MMM dd, HH:mm',
                        minute: 'MMM dd, HH:mm'
                    }
                },
                ticks: {
                    color: '#ddd'
                },
                grid: { color: '#555' },
                title: {
                    display: true,
                    text: 'UTC Time',
                    color: '#ddd',
                    font: { size: 14 }
                }
            },
            y: {
                ticks: yTicksOptions,
                min: yAxisMin, // The minimum value of the y-axis
                max: yAxisMax, // The maximum value of the y-axis
                grid: { color: '#555' },
                title: {
                    display: true,
                    text: yAxisLabel,
                    color: '#ddd',
                    font: { size: 14 }
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#ddd',
                    font: { size: 14 }
                }
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems) {
                        return tooltipItems[0].label;
                    },
                },
            },
            annotation: {
                annotations: {
                    line1: {  // Arbitrary ID for the annotation
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x',
                        value: new Date().toISOString(), // Current time
                        borderColor: 'red', // Line color
                        borderWidth: 2, // Line width
                    }
                }
            }
        },
    };
};

export default getChartOptions;
