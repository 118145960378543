import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  registerables
} from 'chart.js';
import { addHours, format } from 'date-fns';

import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';

import { useTower } from '../contexts/TowerContext';
import getChartOptions from './getChartOptions';

Chart.register(...registerables, annotationPlugin);


const ForecastChartWithConfigurations = ({ tableName, yAxisLabel, yAxisMin, yAxisMax, yAxisDecimals }) => {
  const { selectedTower, setSelectedTower } = useTower();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const fetchAndPrepareForecastData = async () => {
	if (!selectedTower){
	  return;
	}
    const apiUrl = process.env.REACT_APP_FORECAST_API_URL;
    const configurations = [3, 4];
    const dataPromises = configurations.map((configurations) =>
      axios.get(`${apiUrl}/forecast/${tableName}/${selectedTower['id']}/${configurations}`)
    );

    try {
      const responses = await Promise.all(dataPromises);
      const data = responses.map(response => response.data[0]);
      prepareChartData(data);
    } catch (error) {
      console.error("Error fetching forecast data:", error);
    }
  };

  const prepareChartData = (data) => {
    const chartLabels = [];
    const datasets = data.map((dataSet, index) => {
      const baseTimestamp = new Date(dataSet.timestamp);
      const configurationName = dataSet.configurationName;
      const chartValues = [];
  
      Object.keys(dataSet).forEach(key => {
        if (key.startsWith('hour')) {
          const hourOffset = parseInt(key.match(/\d+/)[0], 10);
          const datetime = addHours(baseTimestamp, hourOffset);
          const formattedDateTime = format(datetime, 'yyyy-MM-dd HH:mm');
          if (index === 0) { // Only push labels for the first dataset
            chartLabels.push(formattedDateTime);
          }
          chartValues.push(dataSet[key] * 1000.0); // convert to mm
        }
      });
  
      return {
        label: `${configurationName}`,
        data: chartValues,
        fill: false,
        borderColor: `rgb(${75 + index * 60}, ${192 - index * 40}, ${192 - index * 90})`,
        tension: 0.1,
      };
    });
  
    setChartData({
      labels: chartLabels,
      datasets: datasets,
    });
  };

  useEffect(() => {
    fetchAndPrepareForecastData();
  }, [tableName, selectedTower]);

  return chartData && chartData.datasets && chartData.datasets.length > 0 ? (
    <div style={{ height: '80vh', width: '90vw' }}>
      <Line data={chartData} options={getChartOptions(chartData, yAxisLabel, yAxisMin, yAxisMax, yAxisDecimals)} />
    </div>
  ) : (
    <p>Loading...</p>
  );
  
};

export default ForecastChartWithConfigurations;
